import _isEqual from 'lodash.isequal';
import _merge from 'lodash.merge';
import { borderTemplates } from '@raffle-ai/types-search-frontend';

import type {
  DesktopInstance,
  ExtensionInstance,
  Instance,
  WidgetInstance,
  WidgetTemplate,
} from '~/models';
import { Position } from '~/types';

import apiImgUrl from '/public/assets/tools/api.svg';
import desktopImgUrl from '/public/assets/tools/desktop.svg';
import overlayImgUrl from '/public/assets/tools/overlay-square.svg';
import inlineImgUrl from '/public/assets/tools/inline-square.svg';
import embeddedImgUrl from '/public/assets/tools/embedded-square.svg';
import launcherImgUrl from '/public/assets/tools/launcher-square.svg';
import extensionImgUrl from '/public/assets/tools/extension.svg';

export const defaultSearchResultLimit = (scope: Instance['scope']) =>
  scope === 'internal' ? 25 : 10;

export function getToolDetails(instance: Instance) {
  if (instance.type === 'widget') {
    switch (instance.configuration.behaviour.containers.searchbar) {
      case Position.ELEMENT:
        if (
          instance.configuration.behaviour.containers.results ===
          Position.SIDEBAR
        ) {
          return {
            kind: widgetTemplateDict['inline'].title,
            url: inlineImgUrl,
          };
        }

        return {
          kind: widgetTemplateDict['embedded'].title,
          url: embeddedImgUrl,
        };
      case Position.OVERLAY:
        return {
          kind: widgetTemplateDict['overlay'].title,
          url: overlayImgUrl,
        };
      case Position.LAUNCHER:
      default:
        return {
          kind: widgetTemplateDict['launcher'].title,
          url: launcherImgUrl,
        };
    }
  }

  if (instance.type === 'extension') {
    return {
      kind: toolTypeDict['extension'].title,
      url: extensionImgUrl,
    };
  }

  if (instance.type === 'api') {
    return {
      kind: toolTypeDict['api'].title,
      url: apiImgUrl,
    };
  }

  if (instance.type === 'desktop') {
    return {
      kind: toolTypeDict['desktop'].title,
      url: desktopImgUrl,
    };
  }

  throw new Error('Unknown tool type');
}

export const toolTypeDict: {
  [key in Instance['type']]: {
    id: key;
    title: string;
    description: string;
  };
} = {
  widget: {
    id: 'widget',
    title: 'Widget',
    description:
      'Put a widget on e.g. your website. Choose between four templates.',
  },
  api: {
    id: 'api',
    title: 'API',
    description:
      'Build from the bottom with our API to completely personalize the experience.',
  },
  desktop: {
    id: 'desktop',
    title: 'Desktop App',
    description:
      'Enable the Desktop App tool that can be accessed directly from your PC.',
  },
  extension: {
    id: 'extension',
    title: 'Extension',
    description:
      'Use our customer service extension to improve agent efficiency.',
  },
};

export const widgetTemplateDict: {
  [key in WidgetTemplate]: {
    id: key;
    title: string;
    description: string;
    config: Omit<
      WidgetInstance['configuration']['behaviour']['containers'],
      'element_selector'
    >;
    imgUrl: string;
  };
} = {
  launcher: {
    id: 'launcher',
    title: 'Launcher',
    description:
      'Display a launcher in the bottom corner of your website. Who needs a chatbot anyway.',
    config: {
      content: Position.DEFAULT,
      results: Position.DEFAULT,
      searchbar: Position.LAUNCHER,
    },
    imgUrl: launcherImgUrl,
  },
  embedded: {
    id: 'embedded',
    title: 'Embedded',
    description:
      'If you want search on one of the pages of your site, this is the option to try.',
    config: {
      content: Position.DEFAULT,
      results: Position.DEFAULT,
      searchbar: Position.ELEMENT,
    },
    imgUrl: embeddedImgUrl,
  },
  inline: {
    id: 'inline',
    title: 'Inline',
    description:
      'When you want to have an integrated search experience in any UI.',
    config: {
      content: Position.SIDEBAR,
      results: Position.SIDEBAR,
      searchbar: Position.ELEMENT,
    },
    imgUrl: inlineImgUrl,
  },
  overlay: {
    id: 'overlay',
    title: 'Overlay',
    description:
      'When you want to have an integrated search experience and larger area for the search result.',
    config: {
      content: Position.DEFAULT,
      results: Position.DEFAULT,
      searchbar: Position.OVERLAY,
    },
    imgUrl: overlayImgUrl,
  },
};

export const widgetTemplates = Object.values(widgetTemplateDict);

export function getEmptyWidgetConfig(
  containers: WidgetInstance['configuration']['behaviour']['containers'] = {
    searchbar: Position.LAUNCHER,
    results: Position.DEFAULT,
    content: Position.DEFAULT,
    element_selector: '',
  },
): WidgetInstance['configuration'] {
  return {
    disabled: false,
    appearance: {
      main_color: 'hsl(0, 0%, 0%)',
      button_text_color: 'hsl(0, 0%, 100%)',
      button_label: '',
      show_raffle_branding: true,
      element_height: 70,
      searchbar_height: 48,
      instant_answer_lines: 4,
      custom_logo: {
        enabled: false,
      },
      borders: {
        main: '0.5rem',
        components: '0.25rem',
        chips: '4px',
      },
      enable_glassmorphism: false,
      border: true,
      border_template: 'default',
    },
    behaviour: {
      containers,
      show_preview: false,
      smart_preview: false,
      open_link_same_tab: true,
      enable_autocomplete: false,
      enable_text_fragments: true,
      enable_gpt_summary: false,
      enable_gpt_chat: false,
      enable_gpt_chat_initial_message: true,
      enable_search: true,
      enable_video_marker: false,
      enable_backdrop: false,
      enable_url_web: true,
      enable_url_mobile: true,
      disable_pdf_for_summary: false,
      chat: {
        tone_of_voice: 'formal',
        answer_validation_strategy: 'progressive',
        multilingual: false,
      },
      summary: {
        tone_of_voice: 'formal',
        answer_validation_strategy: 'progressive',
        multilingual: false,
      },
    },
    domain: '',
    hide_on_landing_page: false,
    subdomains_to_hide: [],
    show_support_banner_on_init: false,
    supportChannels: {
      phone: {
        enabled: false,
        options: [],
        availability: {
          always: true,
          recurring_open: [],
          single_closed: [],
        },
      },
      iframe: {
        enabled: false,
        target: 'new_tab',
        url: '',
        availability: {
          always: true,
          recurring_open: [],
          single_closed: [],
        },
      },
      email: {
        enabled: false,
        email: '',
        subject: '',
        send_copy_to_self: false,
        custom_message_to_append: '',
        availability: {
          always: true,
          recurring_open: [],
          single_closed: [],
        },
      },
      chat: {
        enabled: false,
        vendor: '',
        inject_first_message: false,
        force_show_widget: false,
        availability: {
          always: true,
          single_closed: [],
          recurring_open: [],
        },
      },
    },
    text_overrides: {},
    gdprNotification: {
      enabled: false,
      description: '',
      support_channels: {
        enabled: false,
        type: 'new_tab',
        url: '',
        link_text: '',
      },
    },
    search_popup_message: {
      enabled: false,
      label: '',
      body: '',
    },
    top_questions: [],
    feedback: {
      enabled: false,
      allowComment: false,
    },
  };
}

export function inferWidgetTemplate({
  element_selector: _,
  ...containers
}: WidgetInstance['configuration']['behaviour']['containers']) {
  if (_isEqual(widgetTemplateDict['launcher'].config, containers)) {
    return 'launcher';
  } else if (_isEqual(widgetTemplateDict['embedded'].config, containers)) {
    return 'embedded';
  } else if (_isEqual(widgetTemplateDict['inline'].config, containers)) {
    return 'inline';
  } else if (_isEqual(widgetTemplateDict['overlay'].config, containers)) {
    return 'overlay';
  }
  throw Error('The tool does not have a valid containers configuration');
}

function mergeWidgetDefault(tool: WidgetInstance) {
  const defaultConfig = getEmptyWidgetConfig();

  const mergedTool = {
    ...tool,
    configuration: _merge(defaultConfig, tool.configuration),
  };

  if (mergedTool.configuration.supportChannels.phone.options.length === 0) {
    mergedTool.configuration.supportChannels.phone.options.push({
      title: '',
      number: '',
      enabled: true,
      opening_hours: [],
    });
  }

  return mergedTool;
}

function mergeDesktopDefault(tool: DesktopInstance) {
  const defaultConfig = getEmptyWidgetConfig({
    ...widgetTemplateDict['embedded'].config,
    element_selector: '',
  });

  const mergedTool = {
    ...tool,
    configuration: _merge(defaultConfig, tool.configuration),
  };

  return mergedTool;
}

function mergeExtensionDefault(tool: ExtensionInstance) {
  const defaultConfig: ExtensionInstance['configuration'] = { vendors: [] };

  const mergedTool = {
    ...tool,
    configuration: _merge(defaultConfig, tool.configuration),
  };
  mergedTool.configuration.vendors.sort();

  return mergedTool;
}

export function mergeToolDefault(tool: Instance) {
  switch (tool.type) {
    case 'api':
      return tool;

    case 'desktop':
      return mergeDesktopDefault(tool);

    case 'extension':
      return mergeExtensionDefault(tool);

    case 'widget':
      return mergeWidgetDefault(tool);

    default:
      throw Error('Invalid tool type');
  }
}

export function getSearchMessageLabelLength(template: WidgetTemplate) {
  if (template === 'embedded') {
    return 75;
  } else if (template === 'overlay') {
    return 95;
  } else {
    return 50;
  }
}

export function getBorderTemplatesNames() {
  return Object.keys(borderTemplates).map((key) => ({
    value: key,
    label: key[0].toUpperCase() + key.slice(1),
  }));
}

/**
 *
 * @param obj will be an object used for defining enum types
 * e.g.:
 *  const TypeObj = {
 *    value_1: 'value_1',
 *    value_2: 'value_2',
 *  } as const;
 * export type Type = (typeof TypeObj)[keyof typeof TypeObj];
 *
 * This function will return the right object used for Select Inputs
 * based on the object passed as argument.
 */
export const selectValuesFromConstObj = (obj: Record<string, string>) =>
  Object.values(obj).map((value) => ({
    value,
    label: value[0].toUpperCase() + value.slice(1),
  }));
