import {
  GlobeEuropeAfricaIcon,
  ServerStackIcon,
  SparklesIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';

import type {
  DeprecatedFeatureName,
  FeatureName,
  Plan,
  Role,
  Source,
} from '~/models';
import { IntegrationVendor } from '~/types';
import type { HeroIcon, SelectOption, Necessity } from '~/types';

import { adminRoles, raffleAdminRoles } from './permissions';
import { languagesOptions } from './languages';

import drupalLogo from '/public/integrations/drupal.svg';
import humanyLogo from '/public/integrations/humany.png';
import sharepointLogo from '/public/integrations/sharepoint.svg';
import umbracoLogo from '/public/integrations/umbraco.png';
import wordpressLogo from '/public/integrations/wordpress.png';
import zohoLogo from '/public/integrations/zoho.svg';
import wixLogo from '/public/integrations/wix.svg';
import typo3Logo from '/public/integrations/typo3.svg';
import sitefinityLogo from '/public/integrations/sitefinity.svg';
import webflowLogo from '/public/integrations/webflow.svg';
import squarespaceLogo from '/public/integrations/squarespace.svg';
import weeblyLogo from '/public/integrations/weebly.svg';
import magnoliaLogo from '/public/integrations/magnolia.svg';
import hubspotLogo from '/public/integrations/hubspot_cms.svg';
import dreamweaverLogo from '/public/integrations/dreamweaver.svg';
import gravLogo from '/public/integrations/grav.svg';
import kenticoLogo from '/public/integrations/kentico.svg';
import concreteLogo from '/public/integrations/concrete_cms.svg';
import sitecoreLogo from '/public/integrations/sitecore.svg';
import goDaddyLogo from '/public/integrations/godaddy.svg';
import bloggerLogo from '/public/integrations/blogger.svg';
import blinkxLogo from '/public/integrations/blink_x.svg';
import dudaLogo from '/public/integrations/duda.svg';
import liveChatIconURL from '/public/integrations/livechat.svg';
import intercomIconURL from '/public/integrations/intercom.svg';
import zendeskIconURL from '/public/integrations/zendesk.svg';
import outlookIconURL from '/public/integrations/outlook.svg';
import superofficeIconURL from '/public/integrations/super-office.svg';
import confluenceIconURL from '/public/integrations/confluence.svg';
import xlsIconURL from '/public/fileTypes/xls.svg';
import pptIconURL from '/public/fileTypes/ppt.svg';
import docIconURL from '/public/fileTypes/doc.svg';
import pdfIconURL from '/public/fileTypes/pdf.svg';

type SourceTypeField = {
  name: string;
  label: string;
  inputType: HTMLInputElement['type'] | 'select';
  arrayInputType?: HTMLInputElement['type'];
  placeholder?: string;
  options?: SelectOption[];
  requiredRoles?: Role[];
  defaultValue?: unknown;
  necessity?: Necessity;
  description?: string;
  fields?: Array<{
    name: string;
    label: string;
  }>;
  type?: 'credentials';
};

const sourceSyncDict: {
  [key in Source['sync_configuration']['full_scrape_interval']]: {
    label: string;
    value: Source['sync_configuration']['full_scrape_interval'];
  };
} = {
  never: { label: 'Never', value: 'never' },
  daily: { label: 'Daily', value: 'daily' },
  weekly: { label: 'Weekly', value: 'weekly' },
};

const syncOptions = Object.values(sourceSyncDict).map((val) => val);

const webScrapeFields: SourceTypeField[] = [
  {
    name: 'sync_configuration.full_scrape_interval',
    label: 'Syncing frequency - full scrape',
    inputType: 'select',
    options: syncOptions,
    requiredRoles: raffleAdminRoles,
    defaultValue: 'never',
  },
  {
    name: 'sync_configuration.partial_scrape_interval',
    label: 'Syncing frequency - partial scrape',
    inputType: 'select',
    options: syncOptions,
    requiredRoles: raffleAdminRoles,
    defaultValue: 'never',
  },
  {
    name: 'entrypoint_urls[]',
    label: 'Entrypoint URLs',
    placeholder: 'https://example.com',
    inputType: 'array',
    necessity: 'required',
    defaultValue: [],
  },
  {
    name: 'allowed_domains[]',
    label: 'Allowed domains',
    placeholder: 'example.com',
    inputType: 'array',
    necessity: 'required',
    defaultValue: [],
  },
  {
    name: 'url_filters[]',
    label: 'Included URL Filters - (regex)',
    placeholder: '^https?://example.com.*$',
    inputType: 'array',
    arrayInputType: 'url',
    necessity: 'required',
    defaultValue: [],
  },
  {
    name: 'disallowed_url_filters[]',
    label: 'Excluded URL Filters - (regex)',
    placeholder: '^https?://example.com.*$',
    inputType: 'array',
    arrayInputType: 'url',
    defaultValue: [],
  },
  {
    name: 'no_follow_url_filters[]',
    label: 'No-Follow URL Filters  - (regex)',
    placeholder: '^https?://example.com.*$',
    inputType: 'array',
    arrayInputType: 'url',
    requiredRoles: raffleAdminRoles,
    defaultValue: [],
  },
  {
    name: 'no_index_url_filters[]',
    label: 'No-Index URL Filters  - (regex)',
    placeholder: '^https?://example.com.*$',
    inputType: 'array',
    arrayInputType: 'url',
    requiredRoles: raffleAdminRoles,
    defaultValue: [],
  },
  {
    name: 'css_whitelist[]',
    label: 'CSS Whitelist',
    inputType: 'array',
    requiredRoles: raffleAdminRoles,
    defaultValue: [],
  },
  {
    name: 'css_blacklist[]',
    label: 'CSS Blacklist',
    inputType: 'array',
    requiredRoles: raffleAdminRoles,
    defaultValue: [],
  },
  {
    name: 'css_title_selectors[]',
    label: 'CSS Title Selectors',
    inputType: 'array',
    requiredRoles: raffleAdminRoles,
    defaultValue: [],
  },
  {
    name: 'css_metadata_selectors[]',
    label: 'CSS Metadata Selectors',
    inputType: 'objectArray',
    requiredRoles: adminRoles,
    fields: [
      {
        label: 'Label',
        name: 'selector_label',
      },
      {
        label: 'Selector',
        name: 'selector',
      },
    ],
    defaultValue: [],
  },
  {
    name: 'rich_scrape',
    label: 'Rich Scrape',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'scrape_files',
    label: 'Scrape files',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: true,
  },
  {
    name: 'enable_url_parameters',
    label: 'URL Parameters',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'enable_reader_mode',
    label: 'Enable reader mode',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'enable_url_case_insensitive',
    label: 'Enable URL case insensitive',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'disable_robot_tags',
    label: 'Disable robot tags',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'disable_sitemap_crawling',
    label: 'Disable sitemap crawling',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'disable_content_deduplication',
    label: 'Disable content de-noising',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: false,
  },
  {
    name: 'disable_display_none_pruning',
    label: 'Disable display:none pruning',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: true,
  },
  {
    name: 'parse_pdf_titles',
    label: 'Parse PDF titles',
    inputType: 'checkbox',
    requiredRoles: raffleAdminRoles,
    defaultValue: true,
  },
  {
    name: 'comment',
    label: 'Comment',
    inputType: 'textarea',
    requiredRoles: raffleAdminRoles,
  },
];

export const sourceTypeDict: {
  [key in Source['type']]: {
    name: string;
    imgUrl?: string;
    Icon?: HeroIcon;
    id: Source['type'];
    fields: SourceTypeField[];
    description?: string;
    type?: 'credentials';
  };
} = {
  instant_answers: {
    name: 'Instant answers',
    Icon: SparklesIcon,
    id: 'instant_answers',
    fields: [],
    description: 'Quickly provide custom responses for common queries',
  },
  web_scrape: {
    name: 'Web scrape',
    Icon: GlobeEuropeAfricaIcon,
    id: 'web_scrape',
    description: `Use web scrape to automatically gather and index data from the web`,
    fields: webScrapeFields,
  },
  blink_x: {
    name: 'Blink X',
    imgUrl: blinkxLogo,
    id: 'blink_x',
    description:
      'Integrate to Blink X to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  blogger: {
    name: 'Blogger',
    imgUrl: bloggerLogo,
    id: 'blogger',
    description:
      'Integrate to Blogger to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  concrete_cms: {
    name: 'Concrete CMS',
    imgUrl: concreteLogo,
    id: 'concrete_cms',
    description:
      'Integrate to Concrete CMS to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  dreamweaver: {
    name: 'Dreamweaver',
    imgUrl: dreamweaverLogo,
    id: 'dreamweaver',
    description:
      'Integrate to Dreamweaver to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  duda: {
    name: 'Duda',
    imgUrl: dudaLogo,
    id: 'duda',
    description:
      'Integrate to Duda to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  go_daddy: {
    name: 'GoDaddy',
    imgUrl: goDaddyLogo,
    id: 'go_daddy',
    description:
      'Integrate to GoDaddy to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  grav: {
    name: 'Grav',
    imgUrl: gravLogo,
    id: 'grav',
    description:
      'Integrate to Grav to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  hubspot_cms: {
    name: 'Hubspot CMS',
    imgUrl: hubspotLogo,
    id: 'hubspot_cms',
    description:
      'Integrate to Hubspot CMS to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  kentico: {
    name: 'Kentico',
    imgUrl: kenticoLogo,
    id: 'kentico',
    description:
      'Integrate to Kentico to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  magnolia: {
    name: 'Magnolia',
    imgUrl: magnoliaLogo,
    id: 'magnolia',
    description:
      'Integrate to Magnolia to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  sitecore: {
    name: 'Sitecore',
    imgUrl: sitecoreLogo,
    id: 'sitecore',
    description:
      'Integrate to Sitecore to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  sitefinity: {
    name: 'Sitefinity',
    imgUrl: sitefinityLogo,
    id: 'sitefinity',
    description:
      'Integrate to Sitefinity to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  squarespace: {
    name: 'Squarespace',
    imgUrl: squarespaceLogo,
    id: 'squarespace',
    description:
      'Integrate to Squarespace to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  typo3: {
    name: 'TYPO3',
    imgUrl: typo3Logo,
    id: 'typo3',
    description:
      'Integrate to TYPO3 to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  webflow: {
    name: 'Webflow',
    imgUrl: webflowLogo,
    id: 'webflow',
    description:
      'Integrate to Webflow to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  weebly: {
    name: 'Weebly',
    imgUrl: weeblyLogo,
    id: 'weebly',
    description:
      'Integrate to Weebly to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  umbraco: {
    name: 'Umbraco',
    imgUrl: umbracoLogo,
    id: 'umbraco',
    description:
      'Integrate to Umbraco to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  wix: {
    name: 'WiX',
    imgUrl: wixLogo,
    id: 'wix',
    description:
      'Integrate to WiX to automatically gather and index data from the web, expanding your search capabilities',
    fields: webScrapeFields,
  },
  drupal: {
    name: 'Drupal',
    imgUrl: drupalLogo,
    id: 'drupal',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'dialect',
        label: 'Dialect',
        inputType: 'text',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'urls[]',
        label: 'urls',
        inputType: 'array',
        arrayInputType: 'url',
        necessity: 'required',
        defaultValue: [],
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  drupal_economic: {
    name: 'Drupal',
    imgUrl: drupalLogo,
    id: 'drupal_economic',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'dialect',
        label: 'Dialect',
        inputType: 'text',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'urls[]',
        label: 'urls',
        inputType: 'array',
        arrayInputType: 'url',
        necessity: 'required',
        defaultValue: [],
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  ftp: {
    name: 'FTP',
    Icon: ServerStackIcon,
    id: 'ftp',
    description:
      'Connect your FTP to import and manage datasets within your search',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'host',
        label: 'Host',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'port',
        label: 'Port',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'username',
        label: 'Username',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'password',
        label: 'Password',
        inputType: 'password',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'download_limit',
        label: 'Download limit (MB/sec)',
        inputType: 'text',
        placeholder: '',
        defaultValue: '',
      },
      {
        name: 'directories[]',
        label: 'Directories',
        inputType: 'array',
        defaultValue: [],
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  manual_upload: {
    name: 'Manual upload',
    Icon: Square3Stack3DIcon,
    id: 'manual_upload',
    fields: [],
    description:
      'Upload and index data using Manual Upload for full content control',
  },
  humany: {
    name: 'Humany',
    imgUrl: humanyLogo,
    id: 'humany',
    description:
      'Connect Humany and unlock seamless customer interactions through our search solution',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'application_domain',
        label: 'Application domain',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'projection',
        label: 'Portal',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  sharepoint: {
    name: 'SharePoint',
    imgUrl: sharepointLogo,
    id: 'sharepoint',
    description:
      "Integrate SharePoint effortlessly to centralize and search your organization's valuable content",
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'subdomain',
        label: 'Subdomain',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'site',
        label: 'Site',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'client_id',
        label: 'Client ID',
        inputType: 'text',
        placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'client_secret',
        label: 'Client Secret',
        inputType: 'password',
        placeholder: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'root',
        label: 'Root',
        inputType: 'text',
        placeholder: '/src/example',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  umbraco7: {
    name: 'Umbraco 7',
    imgUrl: umbracoLogo,
    id: 'umbraco7',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'dialect',
        label: 'Dialect',
        inputType: 'text',
        placeholder: '',
        defaultValue: '',
      },
      {
        name: 'base_url',
        label: 'Base URL',
        inputType: 'url',
        placeholder: 'https://example.com',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'username',
        label: 'Username',
        inputType: 'text',
        necessity: 'required',
        placeholder: 'user@example.com',
        defaultValue: '',
      },
      {
        name: 'password',
        label: 'Passwrod',
        inputType: 'password',
        placeholder: 'xxxxxxxxxxxx',
        necessity: 'required',
        defaultValue: '',
      },
      { name: 'prefixes[]', label: 'Prefixes', inputType: 'array' },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  umbraco8: {
    name: 'Umbraco 8',
    imgUrl: umbracoLogo,
    id: 'umbraco8',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'dialect',
        label: 'Dialect',
        inputType: 'text',
        placeholder: '',
        defaultValue: '',
      },
      {
        name: 'base_url',
        label: 'Base URL',
        inputType: 'url',
        placeholder: 'https://example.com',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'username',
        label: 'Username',
        inputType: 'text',
        placeholder: 'user@example.com',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'password',
        label: 'Passwrod',
        inputType: 'password',
        placeholder: 'xxxxxxxxxxxx',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'prefixes[]',
        label: 'Prefixes',
        inputType: 'array',
        defaultValue: [],
      },
      {
        name: 'language',
        label: 'Language',
        inputType: 'select',
        options: [
          { value: 'da-DK', label: 'Danish' },
          { value: 'en-GB', label: 'English (GB)' },
          { value: 'en-US', label: 'English (US)' },
        ],
        defaultValue: 'en-US',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  wordpress: {
    name: 'WordPress',
    imgUrl: wordpressLogo,
    id: 'wordpress',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'api_url',
        label: 'API URL',
        inputType: 'url',
        placeholder: 'https://example.com/wp-json/wp/v2',
        necessity: 'required',
        defaultValue: '',
      },
      {
        name: 'routes[]',
        label: 'Routes',
        inputType: 'array',
        defaultValue: [],
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  zendesk: {
    name: 'Zendesk',
    imgUrl: zendeskIconURL,
    id: 'zendesk',
    description:
      'Combine Zendesk support data with our search solution for enhanced customer service insights.',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'email',
        label: 'Email',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'token',
        label: 'Token',
        inputType: 'password',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'subdomain',
        label: 'Subdomain',
        inputType: 'text',
        placeholder: '<subdomain>',
        description: 'https://<subdomain>.zendesk.com',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'language',
        label: 'Language',
        inputType: 'select',
        options: languagesOptions,
        necessity: 'required',
        defaultValue: 'en',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  zoho: {
    name: 'Zoho',
    imgUrl: zohoLogo,
    id: 'zoho',
    description:
      'Empower your team with integrated Zoho data, searchable and actionable at your fingertips.',
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'zoho_domain',
        label: 'Zoho Domain',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'client_id',
        label: 'Client ID',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'client_secret',
        label: 'Client Secret',
        inputType: 'password',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'refresh_token',
        label: 'Refresh Token',
        inputType: 'password',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
  confluence: {
    name: 'Confluence',
    imgUrl: confluenceIconURL,
    id: 'confluence',
    description:
      "Bring Confluence's knowledge base into our search solution for efficient collaboration and discovery.",
    fields: [
      {
        name: 'sync_configuration.full_scrape_interval',
        label: 'Syncing frequency - full scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'sync_configuration.partial_scrape_interval',
        label: 'Syncing frequency - partial scrape',
        inputType: 'select',
        options: syncOptions,
        requiredRoles: raffleAdminRoles,
        defaultValue: 'never',
      },
      {
        name: 'domain',
        label: 'Confluence domain',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'username',
        label: 'Username',
        inputType: 'text',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'api_token',
        label: 'API Token',
        inputType: 'password',
        placeholder: '',
        necessity: 'required',
        defaultValue: '',
        type: 'credentials',
      },
      {
        name: 'space_keys[]',
        label: 'Space keys',
        inputType: 'array',
        necessity: 'required',
        defaultValue: [],
        type: 'credentials',
      },
      {
        name: 'comment',
        label: 'Comment',
        inputType: 'textarea',
        requiredRoles: raffleAdminRoles,
      },
    ],
  },
};

export type AvailableSourceType = Exclude<
  Source['type'],
  'wordpress' | 'umbraco7' | 'umbraco8' | 'drupal' | 'drupal_economic'
>;

export const getEmptySourceConfig = (
  sourceType: AvailableSourceType | null,
  isOnboarding?: boolean,
): Source['configuration'] => {
  if (!sourceType) {
    return {} as Source['configuration'];
  }

  const fields = sourceTypeDict[sourceType]?.fields;

  const emptySourceConfig = {};

  fields.forEach(({ defaultValue, inputType, name }) => {
    if (name === 'sync') {
      return;
    }

    if (name === 'sync_configuration.full_scrape_interval') {
      return;
    }
    if (name === 'sync_configuration.partial_scrape_interval') {
      return;
    }

    let inferredDefault;

    if (defaultValue !== undefined) {
      inferredDefault = defaultValue;
    } else {
      switch (inputType) {
        case 'text':
          inferredDefault = '';
          break;
        case 'array':
        case 'objectArray':
          inferredDefault = [];
          break;
        case 'boolean':
          inferredDefault = false;
          break;
        default:
          inferredDefault = '';
      }
    }

    //@ts-ignore - we know that name is a valid field name
    emptySourceConfig[name.replace('[]', '')] = inferredDefault;
  });

  if (sourceType === 'web_scrape' && isOnboarding) {
    return {
      ...emptySourceConfig,
      rich_scrape: true,
    };
  }

  return emptySourceConfig;
};

type Integration = {
  id: IntegrationVendor;
  name: string;
  description: string;
  iconURL: string;
};
export const availableIntegrations: Integration[] = [
  {
    id: IntegrationVendor.liveChat,
    name: 'LiveChat',
    description: 'Activate raffle Employees for LiveChat',
    iconURL: liveChatIconURL,
  },
  {
    id: IntegrationVendor.intercom,
    name: 'Intercom',
    description: 'Activate raffle Employees for LiveChat',
    iconURL: intercomIconURL,
  },
  {
    id: IntegrationVendor.zendesk,
    name: 'Zendesk',
    description: 'Activate raffle Employees for Zendesk',
    iconURL: zendeskIconURL,
  },
  {
    id: IntegrationVendor.oldZendesk,
    name: 'Zendesk (Legacy Chat)',
    description: 'Activate raffle Employees for Zendesk (Legacy Chat)',
    iconURL: zendeskIconURL,
  },
  {
    id: IntegrationVendor.oldZendeskTicket,
    name: 'Zendesk (Legacy Tickets)',
    description: 'Activate raffle Employees for Zendesk (Legacy Tickets)',
    iconURL: zendeskIconURL,
  },
  {
    id: IntegrationVendor.outlook,
    name: 'Outlook 365',
    description: 'Activate raffle Employees for Outlook 365',
    iconURL: outlookIconURL,
  },
  {
    id: IntegrationVendor.superOffice,
    name: 'Super Office',
    description: 'Activate raffle Employees for Super Office',
    iconURL: superofficeIconURL,
  },
];

export function getSourceTypes() {
  const {
    drupal,
    drupal_economic,
    wordpress,
    umbraco7,
    umbraco8,
    ...sourceTypes
  } = sourceTypeDict;

  return {
    ...sourceTypes,
  };
}

export const addonsTabCategories = [
  'All',
  'Features',
  'Insights',
  'Indexes',
  'User Interfaces',
] as const;

export type AddonsTabCategory = (typeof addonsTabCategories)[number];

export const featureLabelsDict: Record<
  FeatureName | DeprecatedFeatureName,
  {
    description: string;
    label: string;
    docsUrl?: string;
    category: AddonsTabCategory;
    type?: 'numeric' | 'boolean';
  }
> = {
  raffle_search: {
    label: 'Raffle Search',
    description:
      'Use Raffle’s powerful search functionality to retrieve information quickly',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/tools/search/',
  },
  gtp3_summary: {
    label: 'Raffle Search Summary',
    description:
      'Automatically generate summaries of the search results using our LLMs for an enhanced searching experience',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/tools/search/#raffle-summary',
  },
  raffle_chat: {
    label: 'Raffle Chat Assistant',
    description:
      'AI-driven chat assistant for providing instant assistance and answering user queries',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/tools/chat/',
  },
  knowledge_bases: {
    label: 'Multi language',
    description:
      'Support for multiple languages to cater to diverse user demographics and preferences',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai',
    type: 'numeric',
  },
  autocomplete: {
    label: 'Autocomplete',
    description:
      'Enhance user search experience with real-time suggestions as they type',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/search-ui/#autocomplete',
  },
  instant_answer_source: {
    label: 'Banners / Instant Answers',
    description: 'Provide immediate responses to user queries with custom answers',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/integrations/campaigns/',
  },
  sources: {
    label: 'Systems Content Integrations',
    description:
      'Connect and integrate data from multiple sources to enrich search results',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/indexes/',
    type: 'numeric',
  },
  search_api: {
    label: 'Search APIs',
    description:
      'included',
    category: 'User Interfaces',
    docsUrl: 'https://docs.raffle.ai/',
    type: 'numeric',
  },
  widgets: {
    label: 'Widgets',
    description:
      'Search and Chat widgets that are easy to install on your site',
    category: 'User Interfaces',
    docsUrl: 'https://docs.raffle.ai/guides/tools/',
    type: 'numeric',
  },
  power_app: {
    label: 'Power Cloud Apps',
    description: 'placeholder',
    category: 'User Interfaces',
    docsUrl: 'https://docs.raffle.ai/',
  },
  desktop_app: {
    label: 'Power Desktop Apps',
    description: 'Allows for you to use Raffle Search as a desktop application',
    category: 'User Interfaces',
    docsUrl: 'https://docs.raffle.ai/implementations/desktop/',
  },
  customer_service_extension: {
    label: 'Customer Service Extension',
    description: '',
    category: 'User Interfaces',
    docsUrl: 'https://docs.raffle.ai',
  },
  public_api: {
    label: 'Public API',
    description:
      'Enable integration and interaction with external systems through our API',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai/api/',
  },
  keyword_boosting: {
    label: 'Rules Engine',
    description: '',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai',
  },
  storage_mb: {
    label: 'Data (MB)',
    description: 'Increase your storage capabilities',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
    type: 'numeric',
  },
  storage_gbs: {
    label: 'GB Data',
    description: 'Increase your storage capabilities',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
    type: 'numeric',
  },
  storage_pages: {
    label: 'Data (Pages)',
    description: 'Increase your storage capabilities',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
    type: 'numeric',
  },
  basic_insights: {
    label: 'Basic Insights',
    description:
      'included',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/',
  },
  reranking: {
    label: 'Reranking',
    description:
      'placeholder',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/',
  },
  knowledge_gaps: {
    label: 'Content Gaps',
    description:
      'Identify and address gaps in content to improve user engagement and satisfaction',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/#knowledge-gaps',
  },
  trending_questions: {
    label: 'Trending Questions',
    description:
      'Highlight popular and relevant user queries to stay informed about current interests',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/#question-clusters',
  },
  popular_pages: {
    label: 'Popular Pages',
    description:
      'Display frequently accessed pages to facilitate quick navigation and access to popular content',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/#popular-pages',
  },
  chat_history: {
    label: 'Chat History',
    description:
      'placeholder',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/',
  },
  comparison_history: {
    label: 'Comparison History',
    description:
      'placeholder',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai/guides/insights/',
  },
  public_api_bi: {
    label: 'Insights Public API for BI',
    description:
      'Enable integration into your desired business intelligence system',
    category: 'Insights',
    docsUrl: 'https://docs.raffle.ai',
  },
  chat_integration: {
    label: 'Live Chat Integration',
    description:
      'Integrate your live chat vendor as a support option inside our widgets',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/tools/support/#chat-support',
  },
  speech_recognition: {
    label: 'Search in Videos & Speech',
    description:
      'Automatically detect segments in your video content that are relevant for the asked questions',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai/guides/video-and-speech/',
  },
  custom_models: {
    label: 'Custom trained advanced AI Models',
    description: 'included',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
  },
  content_api: {
    label: 'Content API',
    description: 'included',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
  },
  advanced_crawler: {
    label: 'Advanced Crawler',
    description: 'included',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
  },
  private_data: {
    label: 'Personal data handling',
    description: '',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai',
  },
  daily_sync: {
    label: 'Daily Sync',
    description:
      'Keep data up-to-date across systems through daily synchronization',
    category: 'Indexes',
    docsUrl: 'https://docs.raffle.ai/guides/indexes/#sync-frequency',
  },
  trial_access: {
    label: 'Trial Limited Access',
    description: '',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai',
  },
  consumption_based: {
    label: 'Consumption Based Plan',
    description:
      'Enable using credits & paying based on the usage of the system',
    category: 'Features',
    docsUrl: 'https://docs.raffle.ai',
  },
};

export const availableFeatureLabelsDict: () => Record<
  Exclude<
    FeatureName,
    'keyword_boosting' | 'trial_access' | 'customer_service_extension' | 'storage_pages' | 'speech_recognition'
  >,
  (typeof featureLabelsDict)[FeatureName]
> = () => {
  const {
    keyword_boosting,
    speech_recognition,
    storage_mb,
    storage_pages,
    trial_access,
    customer_service_extension,
    ...features
  } = featureLabelsDict;
  return features;
};

export const planOptions: Array<{
  value: Plan['name'];
  label: string;
  descriptionItems?: string[];
}> = [
  {
    value: 'trial',
    label: 'Trial',
  },
  {
    value: 'demo',
    label: 'Demo',
  },
  {
    value: 'build',
    label: 'Build',
  },
  {
    value: 'pps',
    label: 'Consumption',
  },
  {
    value: 'scale',
    label: 'Scale',
  },
  {
    value: 'expand',
    label: 'Expand',
  },
  {
    value: 'chatbot',
    label: 'Chatbot',
  },
  {
    value: 'professional',
    label: 'Professional',
  },
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'enterprise',
    label: 'Enterprise',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export const onboardingPlanOptions: Array<Plan['name']> = [
  'demo',
  'trial',
  'build',
];

export const industryOptions = [
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Software', label: 'Software' },
  { value: 'Telco', label: 'Telco' },
  { value: 'Public Sector', label: 'Public Sector' },
  { value: 'Union', label: 'Union' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Other', label: 'Other' },
];

export const webscrapeCMS = [
  'wordpress',
  'umbraco',
  'drupal',
  'sitecore',
  'sitefinity',
  'weebly',
  'webflow',
  'squarespace',
  'hubspot_cms',
  'concrete_cms',
  'grav',
  'typo3',
  'kentico',
  'magnolia',
  'duda',
  'dreamweaver',
  'blogger',
  'blink_x',
  'go_daddy',
  'wix',
];
export type SourceSubType = (typeof webscrapeCMS)[number];

export const cmsIntegrationTypes = [
  'zendesk',
  'humany',
  'sharepoint',
  'zoho',
  'confluence',
  ...webscrapeCMS,
];

export const fileTypesImg = {
  doc: docIconURL,
  docx: docIconURL,
  ppt: pptIconURL,
  pptx: pptIconURL,
  xls: xlsIconURL,
  xlsx: xlsIconURL,
  pdf: pdfIconURL,
};

export const MAX_FILE_UPLOAD_SIZE = 15_728_640;
