export const fadeInScaleUp = {
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
  transition: {
    duration: 0.25,
    ease: [0, 0.71, 0.2, 1.01],
  },
  exit: { opacity: 0, scale: 0.5 },
};

export const fadeInMoveUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, translateY: -20 },
  transition: {
    duration: 0.25,
  },
  exit: { opacity: 0, translateY: 20 },
};

export const fadeInAndGrow = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  transition: {
    duration: 0.15,
  },
  exit: { opacity: 0, height: 0 },
};

export const slideUpAndFadeIn = {
  initial: { opacity: 0, y: 400 },
  animate: { opacity: 1, translateY: -400 },
  transition: {
    duration: 0.25,
  },
  exit: { opacity: 0, translateY: 400 },
};

export const rotateIn = {
  initial: { scale: 0.9, rotateX: 90 },
  animate: { scale: 1, rotateX: 0 },
  transition: {
    duration: 0.5,
    animationDelay: 1.25,
  },
};

export const slideDown = {
  initial: { y: -100 },
  animate: { translateY: 100 },
  transition: {
    duration: 0.25,
  },
  exit: { translateY: 100 },
};

export const slideRight = {
  initial: { x: '-100%' },
  animate: { translateX: '100%' },
  transition: {
    duration: 0.5,
  },
  exit: { translateX: '-100%' },
};

export const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: {
    duration: 0.35,
  },
  exit: {
    opacity: 0,
  },
};

export const scaleUp = {
  initial: { scale: 1 },
  animate: { scale: 1.25 },
  transition: {
    duration: 0.4,
    type: 'spring',
    stiffness: 80,
    damping: 25,
  },
};

export const growHeightFadeIn = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.15,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
};

export const staggerItems = (index: number) => ({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.65 },
  transition: {
    duration: 0.3,
    delay: index * 0.03,
  },
});
