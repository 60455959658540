export const bannedURLs = [
  'google',
  'youtube',
  'facebook.com',
  'twitter.com',
  'wikipedia',
  'instagram.com',
  'reddit.com',
  'asurascans.com',
  'tiktok.com',
  'fandom.com',
  'amazon.com',
  'weather.com',
  'lectortmo.com',
  'yahoo.com',
  'tsyndicate.com',
  'yandex.ru',
  'manganato.com',
  'whatsapp.com',
  'duckduckgo.com',
  'openai.com',
  'twitch.tv',
  'bing.com',
  'doubleclick.net',
  'live.com',
  'linkedin.com',
  'vk.com',
  'microsoft.com',
  'quora.com',
  'netflix.com',
  'imdb.com',
  'taboola.com',
  'discord.com',
  'aliexpress.com',
  'github.com',
  'office.com',
  'pinterest.com',
  'zoom.us',
  'paypal.com',
  'roblox.com',
  'spotify.com',
  'mail.ru',
  'bbc.com',
  'indeed.com',
  'ebay.com',
  'cnn.com',
  'naver.com',
  'fc2.com',
  'ampproject.org',
  'samsung.com',
  'wordpress.com',
  'globo.com',
  'booking.com',
  'accuweather.com',
  'patreon.com',
  'canva.com',
  'msn.com',
  'imgur.com',
  'walmart.com',
  'sharepoint.com',
  'linktr.ee',
  'adobe.com',
  'stackoverflow.com',
  'tumblr.com',
  'foxnews.com',
  'espn.com',
  'telegram.org',
  'cricbuzz.com',
  'nih.gov',
  'baidu.com',
  'ok.ru',
  'disneyplus.com',
  'deepl.com',
  'medium.com',
  'homedepot.com',
  'speedtest.net',
  'bilibili.com',
  'investing.com',
  'rottentomatoes.com',
  'shopify.com',
  'playstation.com',
  'craigslist.org',
  'outbrain.com',
  'messenger.com',
  'capitalone.com',
  'target.com',
  'nintendo.com',
  'turbopages.org',
  'okta.com',
  'snapchat.com',
  'yelp.com',
  'wiktionary.org',
  'detik.com',
  'cambridge.org',
  'merriam-webster.com',
  'coinmarketcap.com',
  'ea.com',
  'instructure.com',
  'qq.com',
  'figma.com',
  'max.com',
  'naver.com',
  'dzen.ru',
  'baidu.com',
  'xvideos.com',
  'pornhub.com',
  'xnxx.com',
  'xhamster.com',
  'xhamster.desi',
  'stripchat.com',
  'tinder.com',
  'spankbang.com',
  'chatrubate.com',
  'realsrv.com',
  'onlyfans.com',
  'livejsmin.com',
  'eporner.com',
  'dmm.co.jp',
  'noodlemagazine.com',
  'xhamsterlive.com',
  'xcum.com',
  'missav.com',
  'youporn.com',
  'bongacams.com',
  'cityheaven.net',
  'ixxx.com',
  'nhentai.net',
  'rule34.xxx',
  'crmentjg.com',
  'redtube.com',
  'faphouse.com',
  'xvideos2.com',
  'erome.com',
  'dlsite.com',
  'tnaflix.com',
  'txxx.com',
  'youjizz.com',
  'sxyprn.com',
  'dooood.com',
  'hqporner.com',
  'xlivrdr.com',
  'ok.xxx',
  'brazzersnetwork.com',
  'nutaku.net',
  'fapello.com',
  'iporntv.net',
  'boyfriendtv.com',
  'theporndude.com',
  'quorno.com',
  'literotica.com',
  'hitomi.la',
  'upornia.com',
  'jsmcrptjmp.com',
  'pornpics.com',
  'redgifs.com',
  'apple.com',
  'etsy.com',
  'amazonaws.com',
  'dailymail.co.uk',
  'ign.com',
  'hulu.com',
  't.co',
  'bit.ly',
  'ja2r7.app.goo.gl',
  'd8hxy.app.goo.gl',
  'peg3x.app.goo.gl',
  'tinyurl.com',
  'is.gd',
  'lin.ee',
  'cutt.ly',
  'rebrand.ly',
  'algolia.com',
  'boost.ai',
  'coveo.com',
  'cludo.com',
  'dixa.com',
  'elastic.co',
  'forethought.ai',
  'intercom.com',
  'kore.ai',
  'ultimate.ai',
  'solr.apache.org',
  'openai.com',
  'microsoft.com',
  'instagram.com',
  'example',
  'gmail',
  'alice.org',
  'app.raffle.ai',
  'raffle.ai',
];

export const isUrlAllowed = (url: string) => {
  for (let i = 0; i < bannedURLs.length; i++) {
    if (url.includes(bannedURLs[i])) return false;
  }
  return true;
};

export const isAllowedEmailDomain = (email: string) =>
  !(
    email.includes('@gmail') ||
    email.includes('@yahoo') ||
    email.includes('@hotmail') ||
    email.includes('@outlook') ||
    email.includes('@cludo') ||
    email.includes('@coveo') ||
    email.includes('@boost.ai') ||
    email.includes('@algolia') ||
    email.includes('@yopmail') ||
    email.includes('@icloud')
  );

export const bannedPhoneNumbers = [
  '000000',
  '111111',
  '222222',
  '333333',
  '444444',
  '555555',
  '666666',
  '777777',
  '888888',
  '999999',
  '0000000',
  '1111111',
  '2222222',
  '3333333',
  '4444444',
  '5555555',
  '6666666',
  '7777777',
  '8888888',
  '9999999',
  '00000000',
  '11111111',
  '22222222',
  '33333333',
  '44444444',
  '55555555',
  '66666666',
  '77777777',
  '88888888',
  '99999999',
  '123456',
  '234567',
  '345678',
  '456789',
  '567890',
  '1234567',
  '2345678',
  '3456789',
  '12345678',
  '23456789',
  '34567890',
];

export const isAllowedPhoneNumber = (phoneNumber: string) =>
  !bannedPhoneNumbers.includes(phoneNumber.replace(/\s/g, ''));
